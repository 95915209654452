import { useQuery } from '@tanstack/react-query';
import { axiosRequest } from '@dizzbo/core/api';

export const useGetPoDBulkDownloadURL = (token: string, retry: boolean) => {
  const getPoDBulkDownloadURL = async () => {
    const res = await axiosRequest.get<PoDBulkDownloadType>(
      `/v2/downloads/bulk/${token}/`
    );
    return res.data;
  };

  const { data, isPending, error } = useQuery({
    queryKey: ['pods', token],
    queryFn: () => getPoDBulkDownloadURL(),
    retry: retry,
  });

  return {
    error,
    data,
    isPending,
  };
};
