import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from '@dizzbo/ui';

import { Router } from '@core/routes';

const App = () => {
  return (
    <BrowserRouter>
      <Router />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
      />
    </BrowserRouter>
  );
};

export default App;
