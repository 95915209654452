import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  LoadingButton,
  Alert,
} from '@dizzbo/ui';
import { DizzboLogo } from '@dizzbo/ui';
import { View } from '@dizzbo/core/views';

import { useGetPoDDownloadURL } from '../queries';

import HeroBackgroundImage from '../../../assets/images/home-hero.jpg';

type Props = {};

export const PoDDownloadView: React.FC<Props> = () => {
  const { token } = useParams();
  const { data, error, isPending } = useGetPoDDownloadURL(token, false);

  const downloadFile = async (url, fileName) => {
    const response = await fetch(url, { method: 'GET' });
    const blob = await response.blob();
    const urlDownload = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = urlDownload;
    link.setAttribute('download', fileName);
    link.click();
  };

  return (
    <View title="Login">
      <Box
        sx={{
          height: '100vh',
          backgroundImage: `url(${HeroBackgroundImage})`,
          backgroundColor: '#17253C',
          backgroundSize: { xs: 'cover', xl: 'cover' },
          backgroundPosition: 'top right',
          backgroundRepeat: 'no-repeat',
          flexGrow: 1,
        }}
      >
        <Grid container spacing={0} sx={{ height: '100%' }}>
          <Grid
            xs={12}
            md={12}
            sx={{
              backgroundColor: 'rgba(8,19,36,0.6)',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card
              elevation={6}
              variant="filled-primary"
              sx={{
                minWidth: 400,
              }}
            >
              <CardHeader
                avatar={<DizzboLogo sx={{ width: 74, height: 16 }} dark />}
              />
              <CardHeader title="PoD Download" variant="small" />
              <CardContent>
                {error &&
                  (error.response.data.token?.[0] === 'Token expired' ? (
                    <Alert
                      severity="error"
                      title="Error"
                      message="The link has expired."
                    />
                  ) : (
                    <Alert
                      severity="error"
                      title="Error"
                      message="Something went wrong."
                    />
                  ))}
                {!error && (
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="primary"
                    loading={isPending}
                    onClick={() => downloadFile(data?.url, data?.fileName)}
                  >
                    Download PoD
                  </LoadingButton>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </View>
  );
};
